#themeTable_wrapper {
  margin-top: 0px;
}


div.dt-buttons {
  position: fixed;
  /* float: left; */
  top: 69px;
  left: 215px;
  z-index: 99;
}
#themeTable_length {
  position: fixed;
  top: 75px;
  left: 25px;
  z-index: 99;
}
#themeTable_info {
  position: fixed;
  top: 126px;
  left: 840px;
  z-index: 99;
}
#themeTable_paginate {
  position: fixed;
  left: 400px;
  top: 125px;
  z-index: 99;
}
#themeTable_filter label {
  position: fixed;
  left: 22px;
  top: 130px;
  z-index: 99;
}
#themeTable_filter label input {
  background-color: white;
}
.menu-container {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 89 !important;
}
.dtfc-fixed-left {
  border: 1px solid rgb(73, 73, 73);
  width: 100px;
  height: 100px;
  box-shadow: 4px 0 3px -2px rgb(173, 173, 173);
}
.even,
.even td {
  background-color: #ededed !important;
}
.button {
  background-color: #1ea5de;
  border-color: #1ea5de;
  color: #fff;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.3125rem;
}

.button:hover {
  opacity: 1;
}
.menu-container {
  padding-bottom: 115px !important;
}

.btn-cargo {
  color: #ffffff;
  background-color: #a2cc3a;
  border-color: #a2cc3a;
}

.btn-cargo:hover,
.btn-cargo:focus,
.btn-cargo:active,
.btn-cargo.active,
.open .dropdown-toggle.btn-cargo {
  color: #ffffff;
  background-color: #8eb037;
  border-color: #a2cc3a;
}

.btn-cargo:active,
.btn-cargo.active,
.open .dropdown-toggle.btn-cargo {
  background-image: none;
}

.btn-cargo.disabled,
.btn-cargo[disabled],
fieldset[disabled] .btn-cargo,
.btn-cargo.disabled:hover,
.btn-cargo[disabled]:hover,
fieldset[disabled] .btn-cargo:hover,
.btn-cargo.disabled:focus,
.btn-cargo[disabled]:focus,
fieldset[disabled] .btn-cargo:focus,
.btn-cargo.disabled:active,
.btn-cargo[disabled]:active,
fieldset[disabled] .btn-cargo:active,
.btn-cargo.disabled.active,
.btn-cargo[disabled].active,
fieldset[disabled] .btn-cargo.active {
  background-color: #a2cc3a;
  border-color: #a2cc3a;
}

.btn-cargo .badge {
  color: #a2cc3a;
  background-color: #ffffff;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

div.react-bootstrap-table table thead tr th{
  cursor: pointer;
}

.table>tbody>tr:nth-child(odd)>td, 
.table>tbody>tr:nth-child(odd)>th {
   background-color: #ececec;
   color:black;
 }

 .table>tbody>tr:nth-child(even)>td, 
.table>tbody>tr:nth-child(even)>th {
   color:black;
 }

 .table>tbody>tr >th
 {
  color:black;
 }

 .table>tbody>tr >  td, th,  .table>thead>tr > th
 {
  border: 1px solid #b7b7b7;
 }

 .table
 {
  background-color: #fbfbfb;
 }

 .pagination
 {
  margin-top: 8px!important;
  margin-left:4px!important;
  float:left;
 }