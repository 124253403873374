.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading-indicator:before {
    content: "";
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1009;
    opacity: 0.4;
}

.loading-indicator:after {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1010;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    display: inline-block;
    content: "";
    width: 4rem;
    height: 4rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    /* -webkit-animation: spinner-border 0.75s linear infinite; */
    animation: spinner-border 0.75s linear infinite;
}

ol, ul {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") " ";
}
ol .first-level:before {
    color: #1e2022;
    font-size: calc(1.25625rem + 0.075vw);
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.4;
    color: #1e2022;
}
ol :before {
    color: #1a2540;
}

footer a, p
{
    color:#1e2022;
}

.my-tooltip > .tooltip-inner {
    background-color:white;
    color:black;
}
  
.my-tooltip
{
    border: 2 px solid black!important;
}
.my-tooltip > .tooltip-arrow {
    border-top: 5px solid white !important;
}